import { ref } from 'vue';
import { useNotifications } from './use-notifications';
import { getErrorMessage as getErrorMessageUtil } from '@/modules/core/utilities';
import axios from 'axios';

const isActionInProgress = ref(false);
const { showToast } = useNotifications();

export async function useErrorHandling<T> (
    promise: Promise<T> | ((...args: any[]) => Promise<T>),
    getErrorMessage = getErrorMessageUtil
) {
    try {
        isActionInProgress.value = true;
        if (typeof promise === 'function') return await promise();
        return await promise;
    } catch (error: any) {
        let errorMessage = getErrorMessage(error);
        const suppressToast = axios.isAxiosError(error) && error.config?.suppressAuthCheckRedirect;
        if (!suppressToast) {
            showToast({ message: errorMessage, type: 'danger' })
        }
        throw error;
    } finally {
        isActionInProgress.value = false;
    }
}